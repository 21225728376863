<template>
  <div v-if="isShow">
    <!-- <div class="company" v-if="enterpriseType">
      <div class="company_left">
        <upload-image
          :imageUrl.sync="enterpriseInfo.companyLogoLook"
          :saveUrl.sync="enterpriseInfo.companyLogo"
        />
        <div class="info">
          <div>
            <div class="fullName" v-if="!isShowInput">
              <div class="companyFullName">
                {{ enterpriseInfo.companyFullName }}
              </div>
              <i class="jr-iconjr-icon-edit1" @click="editClick" />
            </div>
            <div class="fullName" v-else>
              <el-input v-model="enterpriseInfo.companyFullName" />
              <el-button type="primary" @click="sureInfo">保 存</el-button>
            </div>
          </div>
          <div class="updateTime">
            更新时间：{{ enterpriseInfo.updateTime }}
          </div>
        </div>
      </div>
      <div class="company_right">
        <div>创建人：{{ enterpriseInfo.createName }}</div>
        <div>修改人：{{ enterpriseInfo.updateName }}</div>
        <div>收录时间：{{ enterpriseInfo.createTime }}</div>
      </div>
    </div>
    <el-form
      v-else
      :model="enterpriseInfo"
      :inline="true"
      :rules="rulesCompanyInfo"
      ref="rulesCompanyInfo"
      class="companyInfo"
    >
      <el-form-item class="avatar">
        <upload-image :saveUrl.sync="enterpriseInfo.companyLogo" />
      </el-form-item>
      <el-form-item label="公司全称：" prop="companyFullName">
        <el-input
          v-model="enterpriseInfo.companyFullName"
          class="lager-input"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="saveCompanyInfo">保存</el-button>
      </el-form-item>
    </el-form> -->
    <el-form
      :model="enterpriseInfo"
      :rules="rulesCompanyInfo"
      ref="rulesCompanyInfoOne"
      style="margin-bottom: 0; backgroundcolor: #fff; padding: 20px"
      label-width="100px"
    >
      <el-form-item label="运营状态：" prop="companyOperationalStatus">
        <el-radio-group v-model="enterpriseInfo.companyOperationalStatus">
          <el-radio
            v-for="(item, index) in query.companyOperationalStatus"
            :key="index"
            :label="item.label"
          ></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="公司名称：" prop="companyName">
        <el-input v-model="enterpriseInfo.companyName" style="width: 700px" />
      </el-form-item>
      <el-form-item label="公司简称：" prop="companyShortName">
        <el-input
          v-model="enterpriseInfo.companyShortName"
          class="lager-input"
        />
      </el-form-item>
      <!-- <el-form-item label="专精特新：" prop="companyLevel">
        <el-radio-group v-model="enterpriseInfo.companyLevel">
          <el-radio key="国家级" label="国家级"></el-radio>
          <el-radio key="省级" label="省级"></el-radio>
          <el-radio key="市级" label="市级"></el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="企业资质：" prop="companyLevel">
        <el-checkbox
          v-model="checked1"
          :disabled="disone"
          @change="changeLevel1"
          >专精特新</el-checkbox
        >
        <el-checkbox
          v-model="checked2"
          :disabled="distwo"
          @change="changeLevel2"
          >专精特新小巨人</el-checkbox
        ><el-checkbox v-model="checked3" @change="changeLevel3"
          >规上企业</el-checkbox
        >
        <!-- <el-checkbox-group v-model="checkList" @change="changeLevel">
          <el-checkbox label="专精特新" :disabled="disone" :checked="checkone"
            >专精特新</el-checkbox
          >
          <el-checkbox
            label="专精特新小巨人"
            :disabled="distwo"
            :checked="checkone"
            >专精特新小巨人</el-checkbox
          >
          <el-checkbox label="规上企业" :checked="checkthere"
            >规上企业</el-checkbox
          >
        </el-checkbox-group> -->
        <div class="leave" v-if="le">
          <el-form-item label="级别：" prop="companyLevelType" v-if="leone">
            <el-radio-group v-model="enterpriseInfo.companyLevelType">
              <el-radio key="国家级" label="国家级"></el-radio>
              <el-radio key="省级" label="省级"></el-radio>
              <el-radio key="市级" label="市级"></el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="container" v-if="letwo">
            <div class="block">
              <el-form-item label="认定年份：" prop="certificationYear">
                <el-date-picker
                  v-model="enterpriseInfo.certificationYear"
                  type="year"
                  placeholder="选择年"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form-item>
      <!-- <el-form-item label="行业类别：" label-width="100px">
        <dic-checkbox-group
          :code.sync="enterpriseInfo.industryCategoryList"
          tag="行业"
          ref="industryCategoryList"
          @updateCode="updateCode"
        />
      </el-form-item> -->
      <el-form-item label="简介：" prop="companyBriefIntroduction">
        <el-input
          v-model="enterpriseInfo.companyBriefIntroduction"
          style="width: 700px"
          :autosize="{ minRows: 4, maxRows: 20 }"
          type="textarea"
        />
      </el-form-item>
      <el-form-item label="法定代表人：" prop="legalRepresentative">
        <el-input
          v-model="enterpriseInfo.legalRepresentative"
          style="width: 700px"
        />
      </el-form-item>
      <el-form-item label="注册资本：" prop="registeredCapital">
        <el-input
          v-model="enterpriseInfo.registeredCapital"
          style="width: 700px"
        />
      </el-form-item>
      <el-form-item label="日期：" prop="establishmentDate">
        <data-time
          @dateChange="dateClick"
          :parentYear="Number(enterpriseInfo.year)"
          :parentMonth="Number(enterpriseInfo.month)"
          :parentDay="Number(enterpriseInfo.day)"
        />
      </el-form-item>
      <el-form-item label="所在城市：">
        <!-- <cascader-area
          :country="enterpriseInfo.country"
          :province="enterpriseInfo.province"
          :city="enterpriseInfo.city"
          @searchArae="searchArae"
        /> -->
        <CascaderAreaCity
          :country="enterpriseInfo.country"
          :province="enterpriseInfo.province"
          :city="enterpriseInfo.city"
          :area="enterpriseInfo.region"
          @searchCountry="searchCountry"
          @searchProvince="searchProvince"
          @searchCity="searchCity"
          @searchArea="searchArea"
        >
        </CascaderAreaCity>
      </el-form-item>
      <el-form-item label="电话：" prop="telephone">
        <el-input v-model="enterpriseInfo.telephone" style="width: 700px" />
      </el-form-item>
      <el-form-item label="邮箱：" prop="email">
        <el-input v-model="enterpriseInfo.email" style="width: 700px" />
      </el-form-item>
      <el-form-item label="社保人数：" class="radio_common">
        <el-radio-group v-model="enterpriseInfo.socialSecurityNumber">
          <el-radio
            v-for="(item, index) in query.socialSecurityNumberArray"
            :key="index"
            :label="item.label"
          ></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="企业规模：" prop="enterpriseScale">
        <el-radio v-model="enterpriseInfo.enterpriseScale" label="L(大型)"
          >L(大型)</el-radio
        >
        <el-radio v-model="enterpriseInfo.enterpriseScale" label="M(中型)"
          >M(中型)</el-radio
        >
        <el-radio v-model="enterpriseInfo.enterpriseScale" label="S(小型)"
          >S(小型)</el-radio
        >
        <el-radio v-model="enterpriseInfo.enterpriseScale" label="XS(小微)"
          >XS(小微)</el-radio
        >
      </el-form-item>
      <el-form-item label="融资轮次：" prop="companyFinancingRounds">
        <el-radio v-model="enterpriseInfo.financingRounds" label="未融资"
          >未融资</el-radio
        >
        <el-radio v-model="enterpriseInfo.financingRounds" label="种子轮"
          >种子轮</el-radio
        >
        <el-radio v-model="enterpriseInfo.financingRounds" label="天使轮"
          >天使轮</el-radio
        >
        <el-radio v-model="enterpriseInfo.financingRounds" label="Pre-A轮"
          >Pre-A轮</el-radio
        >
      </el-form-item>
      <el-form-item label="曾用名：" prop="formerName">
        <el-input v-model="enterpriseInfo.formerName" style="width: 700px" />
      </el-form-item>
      <el-form-item label="英文名：" prop="englishName">
        <el-input v-model="enterpriseInfo.englishName" style="width: 700px" />
      </el-form-item>
      <el-form-item label="网址：" prop="website">
        <!-- <el-input v-model="enterpriseInfo.website" style="width:700px" /> -->
        <el-link
          :href="enterpriseInfo.website"
          target="_blank"
          :underline="false"
          >{{ enterpriseInfo.website }}</el-link
        >
        <i
          class="jr-iconjr-icon-edit1"
          style="margin-left: 24px"
          @click="isLinkShow = true"
        ></i>
        <div class="input_button" v-if="isLinkShow">
          <el-input v-model="enterpriseInfo.website" style="width: 470px" />
          <el-button
            type="primary"
            style="height: 40px; margin-left: 24px"
            @click="isLinkShow = false"
            >保存</el-button
          >
        </div>
      </el-form-item>
      <el-form-item label="企业地址：" prop="companyAddress">
        <el-input
          v-model="enterpriseInfo.companyAddress"
          style="width: 700px"
        />
      </el-form-item>
      <el-form-item label="经营范围：" prop="businessScope">
        <el-input
          v-model="enterpriseInfo.businessScope"
          style="width: 700px"
          :autosize="{ minRows: 4, maxRows: 20 }"
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <!-- <div class="editEnterprise" ref="editEnterprise">
      <div class="bottom">
        <el-button type="primary" @click="saveItem">保存</el-button>
        <el-button @click="cancelItem">取消</el-button>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  updateClientEnterprise,
  insertClientEnterprise,
  getClientEnterpriseOne,
} from "@/api/customCompany";
import { getCityAll } from "@/api/common";
import UploadImage from "@/components/UploadImage.vue";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
// import CascaderArea from "@/components/CascaderAreaEnter.vue";
import CascaderAreaCity from "@/components/CascaderAreaCity.vue";
import DataTime from "@/components/DataTime.vue";
export default {
  name: "EnterpriseInfo",
  components: {
    UploadImage,
    DicRadioGroup,
    DicCheckboxGroup,
    // CascaderArea,
    DataTime,
    CascaderAreaCity,
  },
  props: {
    enterpriseInfo: {
      type: Object,
    },
  },
  data() {
    return {
      isLinkShow: false,
      isShow: false,
      query: {
        judgeArray: [
          { label: "是", value: 1 },
          { label: "否", value: 0 },
        ],
        companyOperationalStatus: [
          {
            label: "运营中",
          },
          {
            label: "已关闭",
          },
          {
            label: "已转型",
          },
        ],
        socialSecurityNumberArray: [
          {
            label: "10人以下",
          },
          {
            label: "10-15人",
          },
          {
            label: "15-30人",
          },
          {
            label: "30-50人",
          },
          {
            label: "50-100人",
          },
          {
            label: "100-150人",
          },
          {
            label: "150-500人",
          },
          {
            label: "500-1000人",
          },
          {
            label: "1000-2000人",
          },
          {
            label: "2000人以上",
          },
          {
            label: "不明确",
          },
        ],
      },
      activeName: "first",
      isShowInput: false,
      searchBarFixed: false,
      saveCompanyLogo: "",
      teamInfoTable: [], // 团队信息数据
      rulesCompanyInfo: {
        companyFullName: [
          { required: true, message: "请输入公司全称", trigger: "blur" },
        ],
        // establishmentDate: [
        //   { required: true, message: "请选择日期", trigger: "blur" },
        // ],
      },
      members: [],
      checkList: [], //企业资质数组
      year: "", //认定年份
      disone: false, //专精特新是否禁用
      distwo: false, //专精特新小巨人是否禁用
      leone: false,
      letwo: false,
      le: false,
      checkone: null,
      checktwo: null,
      checkthere: null,

      checked1: "",
      checked2: "",
      checked3: "",
    };
  },
  computed: {
    companyCode() {
      return this.$route.query.id;
    },
    enterpriseType() {
      return Number(this.$route.query.enterpriseType);
    },
  },
  watch: {
    "enterpriseInfo.id": {
      handler(newVal) {
        if (this.enterpriseInfo && newVal) {
          this.isShow = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (this.enterpriseType) {
      this.search();
    }
    if (!this.enterpriseType) {
      this.isShow = true;
    }
  },
  methods: {
    searchCountry(val) {
      this.enterpriseInfo.country = val;
    },
    searchProvince(val) {
      this.enterpriseInfo.province = val;
    },
    searchCity(val) {
      this.enterpriseInfo.city = val;
    },
    searchArea(val) {
      this.enterpriseInfo.region = val;
    },
    // searchArae(val) {
    //   this.enterpriseInfo.country = val.country;

    //   this.enterpriseInfo.province = val.province;

    //   this.enterpriseInfo.city = val.city;
    // },
    updateCode(val, tag) {
      switch (tag) {
        case "行业":
          this.enterpriseInfo.industryCategoryChild = val;
          break;

        case "技术":
          this.enterpriseInfo.techChildrenId = val;
          break;

        default:
          break;
      }
    },
    dateClick(year, month, day) {
      this.enterpriseInfo.year = year;
      this.enterpriseInfo.day = day;
      this.enterpriseInfo.month = month;
    },
    // 查询
    async search() {
      const { data: res } = await getClientEnterpriseOne({
        id: this.companyCode,
      });

      this.enterpriseInfo = res;

      // this.enterpriseInfo.industryCategoryList = this.formatString(
      //   this.enterpriseInfo.industryCategoryList
      // );
      if (this.enterpriseInfo.industryCategory) {
        this.enterpriseInfo.industryCategoryList = this.formatString(
          this.enterpriseInfo.industryCategory
        );
      } else {
        this.enterpriseInfo.industryCategoryList = [];
      }
    },
    // 将字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    editClick() {
      this.isShowInput = true;
    },

    // 修改公司全称
    async sureInfo() {
      const res = await updateClientEnterprise(this.enterpriseInfo);

      this.isShowInput = false;
    },
    // 新增公司全称和公司logo
    saveCompanyInfo() {
      this.$refs.rulesCompanyInfo.validate(async (valid) => {
        if (valid) {
          const params = {
            companyAddParam: this.enterpriseInfo,
            members: this.members,
          };
          const res = await insertClientEnterprise(params);

          if (res.code === 200) {
            this.$message.success("新增成功");
          }
        }
      });
    },
    // 全部保存
    // saveItem() {
    //   Object.assign(this.enterpriseInfo, {
    //     Authorization: this.Authorization,
    //   });
    //   let res;
    //   if (this.enterpriseInfo.day) {
    //     this.$refs.rulesCompanyInfoOne.validate(async (valid) => {
    //       if (valid) {
    //         if (this.enterpriseInfo.industryCategoryChild) {
    //           this.enterpriseInfo.industryCategoryList =
    //             this.enterpriseInfo.industryCategoryList.concat(
    //               this.enterpriseInfo.industryCategoryChild
    //             );
    //         }

    //         // this.enterpriseInfo.industryCategory = [
    //         //   ...new Set(this.enterpriseInfo.industryCategory)
    //         // ];

    //         if (this.enterpriseType) {
    //           // 编辑
    //           res = await updateClientEnterprise(this.enterpriseInfo);
    //         } else {
    //           // 新增
    //           res = await insertClientEnterprise(this.enterpriseInfo);
    //         }

    //         if (res.code === 200) {
    //           this.$message.success(
    //             `${this.enterpriseType ? "编辑" : "新增"}成功`
    //           );

    //           this.$router.go(-1);
    //         } else {
    //           this.$message.error(res.msg);
    //         }
    //       }
    //     });
    //   } else {
    //     this.$message.error("请完善日期");
    //   }
    // },
    cancelItem() {
      this.$router.go(-1);
    },
    // 改变企业资质
    changeLevel1(val) {
      if (val) {
        this.distwo = true;
        this.le = true;
        this.leone = true;
        this.letwo = true;
      } else {
        this.distwo = false;
        this.le = false;
      }
    },
    // 改变企业资质
    changeLevel2(val) {
      if (val) {
        this.disone = true;
        this.le = true;
        this.leone = false;
        this.letwo = true;
      } else {
        this.disone = false;
        this.le = false;
      }
    },
    // 改变企业资质
    changeLevel3(val) {
      // console.log(val, 3);
    },
  },
};
</script>

<style lang="less" scoped>
.company {
  background: #fff;
  padding: 20px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .company_left {
    display: flex;
    .info {
      margin-left: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .fullName {
        display: flex;
        align-items: center;
        .jr-iconjr-icon-edit1:hover {
          color: #4d77ff;
        }
        .el-button {
          margin-left: 10px;
        }
        .companyFullName {
          font-size: 24px;
          color: #363e4d;
        }
        i {
          margin-left: 10px;
        }
      }

      .updateTime {
        color: #7c7f8e;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
  .company_right {
    font-size: 12px;
    color: #7c7f8e;
  }
}
/deep/ .el-tabs__content {
  overflow: auto;
}
.companyInfo {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .el-form-item {
    margin-bottom: 0;
  }
  .avatar {
    margin-right: 20px;
  }
}

.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
.leave {
  width: 426px !important;
  height: 84px !important;
  padding: 10px 0;
  background: #f5f6f7;
}
</style>
