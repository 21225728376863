import request from "@/utils/request";

// 查询
export function getClientEnterpriseServiceList(data) {
  return request({
    url: "/system/clientEnterprise/getClientEnterpriseServiceList",
    method: "post",
    data
  });
}

// 新增
export function insertClientEnterprise(data) {
  return request({
    url: "/system/clientEnterprise/insertClientEnterprise",
    method: "post",
    data
  });
}

// 修改
export function updateClientEnterprise(data) {
  return request({
    url: "/system/clientEnterprise/updateClientEnterprise",
    method: "post",
    data
  });
}

// 删除
export function deleteByIdList(data) {
  return request({
    url: "/system/clientEnterprise/deleteByIdList",
    method: "post",
    data
  });
}

// 单个查询
export function getClientEnterpriseOne(data) {
  return request({
    url: "/system/clientEnterprise/getClientEnterpriseOne",
    method: "get",
    params: data
  });
}

// 导入数据
export function importClientEnterprise(data) {
  return request({
    url: "/system/clientEnterprise/importClientEnterprise",
    method: "post",
    data
  });
}
