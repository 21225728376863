<template>
  <div class="cascaderArea">
    <el-select
      v-model="select.country"
      placeholder="请选择国家"
      @change="changeCountry"
      class="middle-select"
    >
      <el-option value="" label="不限"></el-option>
      <el-option
        v-for="item in list.countrys"
        :key="item.id"
        :label="item.name"
        :value="item.name"
      ></el-option>
    </el-select>
    <el-select
      v-model="select.province"
      placeholder="请选择省份"
      @change="changeProvince"
      class="middle-select"
    >
      <el-option value="" label="不限"></el-option>
      <el-option
        v-for="item in list.provinces"
        :key="item.id"
        :label="item.name"
        :value="item.name"
      ></el-option>
    </el-select>
    <el-select
      v-if="cityTag"
      v-model="select.city"
      placeholder="请选择市"
      @change="changeCity"
      class="middle-select"
    >
      <el-option value="" label="不限"></el-option>
      <el-option
        v-for="item in list.citys"
        :key="item.id"
        :label="item.name"
        :value="item.name"
      ></el-option>
    </el-select>
    <!--  -->
    <el-select
      v-if="areaTag"
      v-model="select.area"
      placeholder="请选择区"
      @change="changeArea"
      class="middle-select"
    >
      <el-option value="" label="不限"></el-option>
      <el-option
        v-for="item in list.areas"
        :key="item.id"
        :label="item.name"
        :value="item.name"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { getCityAll } from "@/api/common";
export default {
  props: {
    country: {
      type: String,
    },
    province: {
      type: String,
    },
    city: {
      type: String,
    },
    area: {
      type: String,
    },
  },
  data() {
    return {
      list: {
        //国家
        countrys: [],
        //省份
        provinces: [],
        //市或区
        citys: [],
        //区
        areas: [],
      },
      select: {
        country: null,
        province: null,
        city: null,
        area: null,
      },
      //是否展示市
      cityTag: true,
      //是否展示区
      areaTag: true,
    };
  },
  watch: {
    country(newVal) {
      if (newVal) {
        this.search();
      }
    },
    "select.province"(newVal) {
      if (newVal === "北京" || newVal === "上海") {
        this.cityTag = false;
        //获取区集合
        this.list.areas = [];
        this.list.provinces.forEach((item) => {
          if (item.name === newVal) {
            this.list.areas = item.cityResults;
          }
        });
      }
    },
  },
  async created() {
    await this.search();
  },
  methods: {
    async search() {
      this.list.countrys = sessionStorage.getItem("area");

      if (!this.list.countrys) {
        const { data: res } = await getCityAll();

        sessionStorage.setItem("area", JSON.stringify(res));
      }

      this.list.countrys = JSON.parse(sessionStorage.getItem("area"));
      if (this.country) {
        this.select.country = this.country;
        this.changeCountry(this.country);
      }
      if (this.province) {
        this.select.province = this.province;
        this.changeProvince(this.province);
      }
      if (this.city) {
        this.select.city = this.city;
        this.changeCity(this.city);
      }

      if (this.area) {
        this.select.area = this.area;
        this.changeArea(this.area);
      }
    },
    //选择国家
    changeCountry(val) {
      //清空省
      this.list.provinces = [];
      //清空市
      this.list.citys = [];
      //清空区
      this.list.areas = [];
      //得到省份集合
      this.list.countrys.forEach((item) => {
        if (item.name === val) {
          this.list.provinces = item.cityResults;
        }
      });
      //触发回调给父组件
      this.$emit("searchCountry", val);
      this.$emit("searchProvince", "");
      this.$emit("searchCity", "");
      this.$emit("searchArea", "");
      //选择国家后清空省市区的选择值
      this.select.province = "";
      this.select.city = "";
      this.select.area = "";
    },
    //选择省份
    changeProvince(val) {
      //判断是否是直辖市
      if (val !== "北京" || val !== "上海") {
        //选择的是省
        this.cityTag = true;
        //获取市集合
        this.list.citys = [];
        this.list.provinces.forEach((item) => {
          if (item.name === val) {
            this.list.citys = item.cityResults;
          }
        });
        //触发省市区父组件回调
        this.$emit("searchProvince", val);
        this.$emit("searchCity", "");
        this.$emit("searchArea", "");
        //清空市区
        this.select.city = "";
        this.select.area = "";
      }
    },
    //选择市
    changeCity(val) {
      //清空区县集合
      this.list.areas = [];
      //找到区县集合
      this.list.citys.forEach((item) => {
        if (item.name === val) {
          this.list.areas = item.cityResults;
        }
      });
      this.$emit("searchCity", val);
      this.select.area = "";
    },
    changeArea(val) {
      this.$emit("searchArea", val);
    },
    clear() {
      this.select = {
        country: "",
        province: "",
        city: "",
        area: "",
      };
    },
  },
};
</script>

<style lang="less" scoped>
.cascaderArea {
  .el-select {
    margin-right: 10px;
  }
}
</style>
