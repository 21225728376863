<template>
  <div>
    <el-upload
      action
      list-type="picture-card"
      :file-list="explainImgList"
      :on-remove="handleRemove"
      accept="image/*"
      :http-request="upload"
      :class="{ hide: hideUpload }"
      :on-preview="handlePictureCardPreview"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { uploadFile } from "@/api/common";
export default {
  props: {
    imageUrl: {
      type: String
    },
    upLoadFile: {
      type: Array,
      default: () => []
    },
    saveUrl: {
      type: String
    }
  },

  data() {
    return {
      fileArray: [],
      hideUpload: false,
      explainImgList: [],
      dialogImageUrl: "",
      dialogVisible: false
    };
  },
  watch: {
    imageUrl: {
      handler: function(newVal, oldVal) {
        if (newVal != oldVal) {
          this.updateExplainImgList();
        }
      },
      immediate: true,
      deep: true
    },
    upLoadFile() {
      this.explainImgList = [];

      this.hideUpload = false;
    }
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    }
  },
  created() {
    if (!this.imageUrl) {
      this.explainImgList = [];
    }
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    updateExplainImgList() {
      if (this.imageUrl) {
        const name = this.imageUrl.substring(
          this.imageUrl.lastIndexOf("/") + 1
        );

        let upLoadFile = [];
        upLoadFile.push({
          name,
          url: this.imageUrl
        });

        this.explainImgList = upLoadFile;

        this.hideUpload = true;
      }
    },
    handleRemove(file) {
      this.$emit("update:imageUrl", "");

      this.$emit("update:saveUrl", "");

      this.explainImgList = [];

      this.fileArray = [];

      this.hideUpload = false;
    },

    async upload(file) {
      this.hideUpload = true;

      const form = new FormData();

      this.fileArray.push(file.file);

      this.fileArray.forEach((item, index) => {
        form.append("files", this.fileArray[index]);
      });

      form.append("Authorization", this.Authorization);

      const res = await uploadFile(form);

      if (res.code === 200) {
        this.$emit("update:imageUrl", res.data.fileLook[0]);

        this.$emit("update:saveUrl", res.data.fileAddr[0]);

        this.explainImgList.push({
          name: file.file.name,
          uid: file.file.uid,
          url: res.data.fileLook[0]
        });
      }
      this.fileArray = [];
    },

    beforeUpload(file) {
      // let FileExt = file.name.replace(/.+\./, '')
      // let flag = ['jpg', 'png', 'gif'].includes(FileExt)
      // const isLt5M = file.size / 1024 / 1024 < 5
      // if (!flag) this.$message.error('文件格式错误请重新上传!')
      // if (!isLt5M) this.$message.error('上传的文件大小不能超过5MB!')
      // return flag && isLt5M
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .el-upload {
  background: #dbdbdb;
}
/deep/ .el-upload--picture-card {
  width: 78px;
  height: 78px;
  line-height: 80px;
}
.hide {
  /deep/ .el-upload--picture-card {
    display: none;
  }
}
</style>
